import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/xbeacon',
      name: 'main',
      component: () => import('./components/Main.vue'),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '/event',
          name: 'event',
          component: () => import('./components/Event.vue')
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('./components/Dashboard.vue')
        },
        {
          path: '/linePhotos',
          name: 'linePhotos',
          component: () => import('./components/LinePhotos.vue')
        },
        {
          path: '/frame',
          name: 'photoFrameUsage',
          component: () => import('./components/PhotoFrameUsage.vue')
        },
        {
          path: '/fotoya/creators',
          name: 'FotoyaCreator',
          component: () => import('./components/fotoya/creator/Creator.vue')
        },
        {
          path: '/fotoya/machine',
          name: 'FotoyaMachine',
          component: () => import('./components/fotoya/machine/Machine.vue')
        },
        {
          path: '/fotobooth/creators',
          name: 'FotoboothCreator',
          component: () => import('./components/fotobooth/creator/Creator.vue')
        },
        {
          path: '/fotobooth/machines',
          name: 'FotoboothMachine',
          component: () => import('./components/fotobooth/machine/Machine.vue')
        },
        {
          path: '/fotobooth/mainReport',
          name: 'FotoboothCreatorMainReport',
          component: () => import('./components/fotobooth/creator/MainReport.vue')
        },
        {
          path: '/fotobooth/order',
          name: 'FotoboothOrder',
          component: () => import('./components/fotobooth/order/Order.vue')
        },
        {
          path: '/fotobooth/dailyReport',
          name: 'FotoboothOrderDailyReport',
          component: () => import('./components/fotobooth/order/DailyReport.vue')
        },
        {
          path: '/fotobooth/analysis',
          name: 'FotoboothOrderAnalysis',
          component: () => import('./components/fotobooth/order/Analysis.vue')
        },
        {
          path: '/fotobooth/deviceList',
          name: 'FotoboothDeviceList',
          component: () => import('./components/fotobooth/device/DeviceList.vue')
        },
        {
          path: '/fotobooth/linkList',
          name: 'FotoboothLinkList',
          component: () => import('./components/fotobooth/link/Link.vue')
        },
        {
          path: '/fotobooth/couponList',
          name: 'FotoboothCouponList',
          component: () => import('./components/fotobooth/coupon/List.vue')
        },
        {
          path: '/fotobooth/ticketList',
          name: 'FotoboothTicketList',
          component: () => import('./components/fotobooth/ticket/TicketList.vue')
        },
        {
          path: '/fotobooth/kiosk',
          name: 'FotoboothKioskSettingsPage',
          component: () => import('./components/fotobooth/kiosk/index.vue')
        },
        {
          path: '/fotobooth/payment/list',
          name: 'FotoboothPaymentList',
          component: () => import('./components/fotobooth/payment/index.vue')
        }
      ]
    },
    {
      path: '/login',
      component: () => import('./components/Login.vue')
    },
    {
      path: '/property/:machineId',
      name: 'property',
      component: () => import('./components/Property.vue')
    },
    {
      path: '/fotobooth/device/log/:deviceName',
      name: 'FotoboothDeviceIndividualLog',
      component: () => import('./components/fotobooth/device/IndividualLog.vue')
    },
    {
      path: '/fotobooth/creator/report/:creatorId',
      name: 'FotoboothCreatorIndividualReport',
      component: () => import('./components/fotobooth/creator/IndividualReport.vue')
    },
    {
      path: '/fotobooth/machine/report/:machineId',
      name: 'FotoboothMachineIndividualReport',
      component: () => import('./components/fotobooth/machine/IndividualReport.vue')
    },
    {
      path: '/fotobooth/order/download/:orderId',
      name: 'FotoboothOrderDownloadPage',
      component: () => import('./components/fotobooth/order/DownloadPage.vue')
    },
    {
      path: '/fotoya/creator/report/:creatorId',
      name: 'FotoyaCreatorIndividualReport',
      component: () => import('./components/fotoya/creator/IndividualReport.vue')
    },
    {
      path: '/fotobooth/find-photos',
      name: 'couponPhotos',
      component: () => import('./components/fotobooth/order/FindPhotos.vue')
    },
    {
      path: '/fotobooth/coupon/order/:orderId',
      name: 'FotoboothCouponOrder',
      component: () => import('./components/fotobooth/coupon/Order.vue')
    },
    {
      path: '*',
      name: '404',
      component: () => import('./components/NotFound.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !store.state.isAuthenticated
  ) {
    next('/login')
  } else {
    next()
  }
})

export default router
