// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import axios from 'axios'
import ElementUI from 'element-ui'
import VueMoment from 'vue-moment'
import Async from 'async'
import md5 from 'md5'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'
import 'tailwindcss/tailwind.css'

Vue.use(ElementUI, { locale })
Vue.prototype.$md5 = md5
Vue.prototype.$http = axios
Vue.prototype.$moment = VueMoment
Vue.prototype.$async = Async

Vue.config.productionTip = false
Vue.use(ElementUI).use(VueMoment)
new Vue({
  created () {
    console.log('created')
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
